.about_bannerBg{
    background-color: #ceaf84;
}


.custom-accordian .accordion-item {
    border-radius: 0 !important;
    border: none;
    border-bottom: 1px solid #c8c8c8;
    outline: none !important;

  }

  .custom-accordian .accordion-button:not(.collapsed) {
    box-shadow: none;
}

.custom-accordian .accordion-button{
font-size: 24px;
color: #6d6d6d;
}



  .custom-accordian .accordion-button:not(.collapsed) {
    background-color: transparent;
    outline: none !important;
}


.custom-accordian .accordion-body {
    padding-top: 0;

}



@media (max-width:1024px){

  .about_bannerBg h1{
    font-size: 19px;
  }

  .about_bannerBg h3{
    font-size: 14px;
  }

  .aboutData h4{
    font-size: 18px;
  }

  .aboutData h2{
    font-size: 20px;

  }

  .custom-accordian .accordion-button{
    font-size: 20px;
  }
  
}