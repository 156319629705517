  .top-header {
    background-color: #265a5d;
    width: 100%;
    height: auto;
    padding-bottom: 4px;
  }

  .contactIcon {
    color: #ffffff;
    cursor: pointer;
  }
  
  .contactIcon:hover {
    color: #ceaf84;
  }
