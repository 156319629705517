.custom_tab .nav-link {
    font-size: 20px;
    line-height: 3rem;
    border: none;
}
.custom_tab .nav-link img {
    max-height: 42px;
    -webkit-filter: grayscale(100);
    filter: grayscale(100%);
    opacity: 0.66;
    transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    overflow: hidden;
    transition-duration: 0.4s;
  }



  .custom_tab .nav-link.active img {
    -webkit-filter: grayscale(0);
    filter: grayscale(0%);
  }



.custom_tab .nav-link {
    display: block;
    color: #333;
    text-decoration: none;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out;


}

.custom_tab .nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active {
    color: #ceaf84;
    border: none;
    background: none;
    font-size: 20px;
    border-bottom: 4px solid #ceaf84;
    border-radius: 0px;
    line-height: 3rem;


}



@media (max-width:900px){
  .custom_tab .nav-link {
    font-size: 16px;
    line-height: 2rem;
}

.custom_tab .nav-link img {
  max-height: 30px;
}
.tab-content img{
  width: 80px;
  display: block;
}
.nav-link {

  padding: 0.5rem 0.5rem;

}
.custom_tab .nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active {
  font-size: 16px;
  line-height: 2rem;
}
}


@media (max-width:600px){
  .custom_tab .nav-link img {
    display: none;
  }

  .custom_tab .nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active {
    font-size: 16px;
    line-height: 2rem;
}
.nav-link {

  padding: 0.5rem 0.5rem;

}

.tab-content img{
  width: 40px;
  display: block;
}
.tab-content h4{
  font-size: 16px;
}

.tab-content p{
  font-size: 14px;
}

.call_bg  h2{
  font-size: 16px;
}
}