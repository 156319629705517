
ul.social-set {
    margin: 0;
    display: flex;
    padding: 0;
    margin-top: 20px;
   
  }
  ul.social-set li {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }
  /* Font Awesome icons */
  a.sociali {
    font-size: 1.5em;
    background: #265a5d;
    color: #fff;
    margin-right: 10px;
    margin-bottom: 10px !important;
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;


  }
  a.sociali:hover,
  a.sociali:focus {
    background: #ceaf84;
  }
  

  .contact-bg-form{
    width: 100%;
    background: url(../../images/contact-bg-form.jpg) no-repeat;
    background-size: cover;
    
  }



  .feedback_bg{
    width: 100%;
    background: url(../../images/form-bg.jpg) no-repeat;
    background-size: cover;
  }



  .custom-button{
    background-color: #265a5d;
    border: none;
 

  }

  .custom-button:hover{
    background-color: #ceaf84;
    border: none;

  }