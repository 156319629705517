
.heading_divider1 h6 {
    font-size: 16px;
    color: #c7a481;
  }
  
  .heading_divider1 h2 {
    color: #265a5d;
  }
  
  .heading_divider1 h6:before {
    display: inline-block;
    margin: 0 15px 0px 0;
    height: 56px;
    content: " ";
    text-shadow: none;
    width: 22px;
    background: url("../../images/heading-left-vline.jpg") 5px 0px;
    background-repeat: no-repeat;
    vertical-align: middle;
  }
  
  .our_services {
    background-image: url("../../images/what_we_do_bg.jpg");
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
  }
  
  .our_services .elementor-cta__content {
    min-height: 440px;
    border-style: solid;
    border-width: 0 1px 0 0;
    border-color: #ffffff36;
    justify-content: flex-start;
  }
  
  .what_we_do {
    width: auto;
    height: 440px;
    display: flex;
    align-items: flex-end;
    overflow: hidden;
  }
  
  .wwd_content {
    width: 100%;
    padding: 15px 25px;
    box-sizing: border-box;
    align-items: center;
    color: white;
    transform: translateY(30%);
    transition: all 0.35s ease;
  }
  
  .wwd_content p {
    opacity: 0;
    transition: all 0.35s ease;
  }
  
  .wwd_content h2 {
    font-family: "Bungee Outline", cursive;
    font-size: 90px;
    transition: all 0.35s ease;
  }
  
  .wwd_content h5 {
    transition: all 0.35s ease;
  }
  
  .what_we_do:hover .wwd_content {
    transform: translateY(0%);
    background: #265a5d;
  }
  
  .what_we_do:hover .wwd_content p {
    opacity: 9;
    transition: all 0.35s ease;
  }
  
  .what_we_do:hover .wwd_content h2 {
    transition: all 0.35s ease;
    color: #ceaf84;
  }
  .what_we_do:hover .wwd_content h5 {
    transition: all 0.35s ease;
    color: #ceaf84;
  }
  

  
.readMore {
  background: #ceaf84;
  color: #fff;
  padding: 6px 15px;
  width: auto;
  position: relative;
  font-size: 16px;
  text-decoration: none;
}

.readMore:hover {
  color: #fff;
  background: #ceaf84;
}

.readMore:before,
.readMore:after {
  border-color: transparent;
  -webkit-transition: all 0.25s;
  transition: all 0.25s;
  border-style: solid;
  border-width: 0;
  content: "";
  height: 25px;
  position: absolute;
  width: 24px;
}

.readMore:before {
  border-color: #ceaf84;
  border-left-width: 2px;
  border-top-width: 2px;
  left: -5px;
  top: -5px;
}

.readMore:after {
  border-bottom-width: 2px;
  border-color: #ceaf84;
  border-right-width: 2px;
  bottom: -5px;
  right: -5px;
}

.readMore:hover:before,
.readMore:hover:after {
  height: 100%;
  width: 100%;
  border-color: #ceaf84;
}