

  
  .own_testimonials .item p {
    text-align: justify;
  }
  
  .own_testimonials .item .qoutes_box {
    background-color: #fff;
    position: absolute;
    right: 0;
    top: 0;
    width: 50px;
    height: 50px;
  }
  



  .own_testimonials .item .qoutes_box img {
    width: 22px;
    height: 22px;
  }
  
  
  .own_testimonials .item .middle {
    transition: 0.5s ease;
    opacity: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    text-align: center;
    background-color: #265a5d;
    padding: 10px;
  }
  
  .own_testimonials .item .reduce_opacity {
    transition: 0.5s ease;
    opacity: 1;
  }
  
  .own_testimonials .item:hover{
    cursor: pointer;
  }

  .own_testimonials .item:hover .reduce_opacity {
    transition: 0.5s ease;
    opacity: 0;
  }
  
  .own_testimonials .item:hover .middle {
    transition: 0.5s ease;
    opacity: 1;

  }

  .bottom-15 {
    bottom: 15% !important;
  }
  .bg-white-50 {
    background-color: rgba(255, 255, 255, 0.8);
  }