@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Bungee+Outline&display=swap");


:host {
  --divider-width: 6px !important; 



}


.divider:after {
   border-left-width: var(--divider-width)  !important;

}

:root {
  --blueColor: #265a5d;
  --carousel-button-color: #fff;
  --fancybox-color: #fff;
  --bg-color: #ffffff;

}

::-webkit-scrollbar {
  width: 7px;
  background-color: #aaa;
}
::-webkit-scrollbar-thumb {
  background-color: #000;
  border-radius: 5px;
}
::selection {
  background: #222;
  color: #fff;
}
::-moz-selection {
  background: #222;
  color: #fff;
}
::-moz-placeholder {
  color: inherit;
  opacity: 1;
}
::-ms-input-placeholder {
  color: inherit;
  opacity: 1;
}
::-webkit-input-placeholder {
  color: inherit;
  opacity: 1;
}
.form-control:focus,
.form-select:focus {
  outline: none;
  box-shadow: none;
  border-color: inherit;
}

a:hover {
  cursor: pointer;
}
html {
  scroll-behavior: smooth;
}
body {
  font-family: "Montserrat", sans-serif;
  color: #646464;
  line-height: 1.4;
  background: var(--bg-color);
  font-size: 17px;
}
h1,h2,h3,h4,h5,h6{

  font-family: "Montserrat", sans-serif;

}

p{
  font-family: "Montserrat", sans-serif;
  font-size: 17px;
  text-align: justify;

}



.own_testimonialss .item {
  background-color: #265a5d;
}

.own_testimonialss .item p {
  text-align: justify;
}

.own_testimonialss .item .qoutes_box {
  background-color: #fff;
  position: absolute;
  right: 0;
  top: 0;
  width: 50px;
  height: 50px;
}

.own_testimonialss .item .qoutes_box img {
  width: 22px;
  height: 22px;
}



.about_bannerBg .breadcrumb-bg{
  background-color: #265a5d;
}

.about_bannerBg .breadcrumb-item a {
  color: #fff !important;
  text-decoration: none;
  font-size: 18px !important;
}

.about_bannerBg .breadcrumb-item.active {
  color: #fff !important;
  font-size: 18px !important;
}


@media (max-width:1024px){

  .residential h2{
    font-size: 21px;
  }


}