.call_bg {
    background-color: #ceaf84;
  }


  .nav-link {
    display: block;
    padding: 0.5rem 1rem;
    color: #333;
    text-decoration: none;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
      border-color 0.15s ease-in-out;
  }

  #footer img{
    width: 200px;
  }

  @media (max-width:680px){
    #footer img{
      width: 200px;
    }
}