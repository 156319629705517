.navbar-brand img{
    width: 230px;
}

.navbar-dark .navbar-nav .nav-link {
    color: rgb(255, 255, 255);
  }
  
  .navbar-dark .navbar-nav .nav-link:hover {
    color: rgb(255, 255, 255);  
    }
  
  .navbar .navbar-nav .nav-link {
    font-family: "Montserrat", sans-serif;
    color: #444;
    font-size: 16px;
    padding: 0.5em 1em;
    margin-right: 2px;
    font-weight: 500;
  }
  
  .navbar-nav .nav-link.active,
  .navbar-nav .show > .nav-link {
    color: #fff;
    background-color: #ceaf84;
  }
  
  .navbar-nav .nav-link:hover {
    background-color: #ceaf84;
    color: #ffffff;
  }
  
  
  .dropdown:hover .nav-link {
    background-color: #ceaf84;
    color: #ffffff;
    border-radius: 0px;
  }
  
  .dropdown-toggle::after {
    display: inline-block;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: "";
    border-top: 0.3em solid;
    border-right: 0.3em solid transparent;
    border-bottom: 0;
    border-left: 0.3em solid transparent; display: none ;
  }

  .dropdown-menu{
    border-radius: 0px;
    font-size: 16px;

  }
  
  .dropdown-item:active {
    background-color: #ceaf84;
    font-size: 16px;

    }

    .dropdown-item.active, .dropdown-item:active {
    background-color: #ceaf84 !important;
    border-radius: 0;
    font-size: 16px;

  }

  .HomeIcon{
    font-size: 24px;
  }


